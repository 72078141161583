<template>
  <div class="login-form-container">
    <form @submit.prevent="handleSubmit" class="signInForm">
      <div class="row">
        <label class="label" for="email">Email</label>
        <input class="input" id="email" placeholder="Email" v-model.lazy="email" type="text" />
      </div>
      <div class="row" v-if="v$.email.$error">
        <span class="error">Username is required</span>
      </div>
      <div class="row">
        <label class="label" for="password">Password</label>
        <input
          class="input"
          id="password"
          placeholder="Password"
          v-model.lazy="password"
          type="password"
        />
      </div>
      <div class="row" v-if="v$.password.$error">
        <span class="error">
          Password is required and
          <br />should be atleast 4 characters.
        </span>
      </div>
      <div class="row" v-if="errMsg">
        <span class="error">{{ errMsg }}</span>
      </div>
      <div class="row right">
        <button class="button login" type="submit">Login</button>
      </div>
      <hr />
    </form>

    <div class="altbuttons">
      <div class="row">
        <section id="firebaseui-auth-container"></section>
      </div>
      <div class="row">
        <button class="button register" @click="register">Register</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { useVuelidate } from "@vuelidate/core";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
const router = useRouter();
const store = useStore();
const errMsg = ref();
const email = ref("");
const password = ref("");

const rules = {
  email: { required, $autoDirty: true },
  password: { required, minLength: minLength(4), $autoDirty: true },
};

const v$ = useVuelidate(rules, { email, password });

onMounted(() => {
  const ui = new firebaseui.auth.AuthUI(getAuth());
  var uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function () {
        successfullyAuthenticated();
        return false;
      },
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };
  ui.start("#firebaseui-auth-container", uiConfig);
});

function register() {
  router.push({ name: "register" });
}

function successfullyAuthenticated() {
  store.dispatch("setAuthenticated", true);
}

function login() {
  // perform login validation
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(() => {
      // Handle successful sign in
      console.log(auth.currentUser);
      successfullyAuthenticated();
      // navigate to home route
      router.push({ name: "home" });
    })
    .catch((error) => {
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          errMsg.value = "Invalid email";
          break;
        case "auth/user-not-found":
          errMsg.value = "User not found";
          break;
        case "auth/wrong-password":
          errMsg.value = "Incorrect password";
          break;
        default:
          errMsg.value = "Email or password was incorrect";
          break;
      }
    });
}

watch(
  () => store.state.isAuthenticated,
  (result) => {
    if (result) {
      // Authentication successful
      // Perform necessary actions here
      router.push({ name: "home" });
    }
  }
);

async function handleSubmit() {
  if (!(await v$.value.$validate())) return;
  login();
  // Submit the form
  // Send HTTP request to server to verify user's credentials
  // Store user's session data in Vuex store or local storage
  // Redirect to main app
}
</script>

<style scoped>
.signInForm > .row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

hr {
  height: 1px;
  background-color: #104d24;
  border: none;
  margin: 20px 0 10px 0;
}
.login-form-container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: none;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: rgb(38, 68, 38);
  color: white;

  font-weight: 600;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255);
}

button:hover,
button:focus {
  background-color: rgb(123, 164, 253);
}

.login {
  width: 120px;
  height: 40px;
}

.register {
  background-color: rgb(38, 68, 38);
  color: white;
  width: 188px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
}

.right {
  text-align: right;
}

.error {
  font-size: small;
  color: red;
}

.label {
  margin-right: 10px;
  color: rgb(24, 156, 115);
  font-weight: 600;
}

.input {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgb(134, 179, 115);
  color: rgb(41, 105, 47);
  background-color: rgb(214, 255, 237);
}
</style>
