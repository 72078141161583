<template>
  <div>
    <h1>Register</h1>
    <p>
      <input type="text" placeholder="Email" v-model="email" />
    </p>
    <p>
      <input type="password" placeholder="Password" v-model="password" />
    </p>
    <p>
      <button @click="register">Submit</button>
    </p>
    <p>
      <button @click="signInWithGoogle">Sign In With Google</button>
    </p>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useRouter } from "vue-router";
const store = useStore();
const email = ref("");
const password = ref("");
const router = useRouter();

const register = () => {
  const auth = getAuth();
  createUserWithEmailAndPassword(getAuth(), email.value, password.value)
    .then(() => {
      console.log("Successfully registered");
      console.log(auth.currentUser);
      successfullyAuthenticated;
      router.push("/home");
    })
    .catch((error) => {
      console.log(error.code);
    });
};

function successfullyAuthenticated() {
  store.dispatch("setAuthenticated", true);
}

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(getAuth(), provider)
    .then(() => {
      successfullyAuthenticated;
      router.push("/home");
    })
    .catch((error) => {
      console.log(error.code);
      //handle error
    });
};
</script>
