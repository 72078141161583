import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      isAuthenticated: false
    }
  },
  mutations: {
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    }
  },
  actions: {
    setAuthenticated(context, isAuthenticated) {
      context.commit('setAuthenticated', isAuthenticated)
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated
    }
  }
})

export default store
