<template>
  <div id="app" class="page-container">
    <div class="image-container" ref="imageContainer">
      <img class="logo" alt="Logo" src="./assets/logo.png" />
    </div>
    <router-view />

    <div class="row">
      <a href>
        <img src="./assets/js.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/css3-1.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/firebase.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/vs.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/nodejs.svg" class="stack" />
      </a>
    </div>
  </div>
</template>

<script>
//import Login from './components/Login.vue'
import { ref, onMounted } from "vue";

export default {
  name: "App",
  setup() {
    const imageContainer = ref(null);

    const addMargin = () => {
      const containerHeight = imageContainer.value.offsetHeight;
      imageContainer.value.style.marginBottom = `${containerHeight / 4}px`;
    };

    onMounted(() => {
      addMargin();
    });

    return {
      imageContainer,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.stack {
  margin: 40px 15px 15px 15px;
  width: 40px;
  height: 40px;
}

.stack:hover {
  margin-top: 35px;
  height: 45px;
}

.image-container {
  align-items: center;
}

.logo {
  max-width: 100%;
  min-width: 380px;
  margin: 0 auto;
  margin-top: 60px;
}

.page-container {
  background-color: #aaf8d5dc;
  padding: 80px 100px 0 100px;
  width: 50%;
  height: 40%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 8px;
  border: 3px solid green;
}

@media (max-width: 800px) {
  .page-container {
    background-color: #aaf8d5dc;
    padding: 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    margin: 0px;
    border-radius: 0px;
    border: 0px solid green;
  }
}

html,
body {
  margin: 0px;
  height: 100%;
}

body {
  background-image: url("./assets/green-background2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
