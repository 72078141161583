import { createRouter, createWebHistory } from 'vue-router'
import LoginView from './components/LoginView.vue'
import HomeView from './components/HomeView.vue'
import RegisterView from './components/RegisterView.vue'
import CodeView from './components/CodeView.vue'
import store from './store'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true, // this route requires authentication
      componentName: "home",
    }
  },
  { 
    path: '/home', 
    redirect: '/',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false,  componentName: "login" } // This route does not require authentication
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { requiresAuth: false, componentName: "register" } // This route does not require authentication
  },
   {
    path: '/code',
    name: 'code',
    component: CodeView,
    meta: { requiresAuth: false, componentName: "code" } // This route does not require authentication
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      console.log("Auth Failed:" + isAuthenticated)
      next ({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
