<template>
  <div>
    <button @click="copyToClipboard">Copy Code</button>
    <pre>
    <code ref="codeContainer" class="language-javascript"></code>
  </pre>
  </div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css";

export default {
  props: {
    filePath: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.loadFileContent();
  },
  methods: {
    async loadFileContent() {
      try {
        const response = await fetch(this.filePath);
        const fileContent = await response.text();
        this.$refs.codeContainer.textContent = fileContent;

        // Format the code using Prism.js
        Prism.highlightElement(this.$refs.codeContainer);
      } catch (error) {
        console.error("Error loading file:", error);
      }
    },
    copyToClipboard() {
      const range = document.createRange();
      range.selectNode(this.$refs.codeContainer);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      // You can add some UI feedback here to indicate successful copy
    },
  },
};
</script>

<style>
pre code {
  white-space: pre;
}

button {
  background-color: rgb(38, 68, 38);
  color: white;
  padding: 5px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid rgb(255, 255, 255);
}
</style>