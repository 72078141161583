<template>
  <div>
    <FileLoader filePath="../ui/UiDropdown.vue" />
  </div>
</template>

<script>
import FileLoader from "../util/FileLoader.vue";

export default {
  components: {
    FileLoader,
  },
};
</script>

<style>
/* Example CSS for code block */
pre {
  background-color: #f8f8f8;
  padding: 1em;
  height: 500px;
}
</style>