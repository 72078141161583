import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAuth } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
 const firebaseConfig = {
  apiKey: "AIzaSyAIG0kcnQtLiPe-GJp_t_Hd472aS4NKn4c",
  authDomain: "shamudeen-a754e.firebaseapp.com",
  projectId: "shamudeen-a754e",
  storageBucket: "shamudeen-a754e.appspot.com",
  messagingSenderId: "468787916272",
  appId: "1:468787916272:web:7554aec448e11848e90135",
  measurementId: "G-XN2QBDCSD4"
};

// Initialize Firebase
initializeApp(firebaseConfig);
//getAuth(firebaseApp);
//const analytics = getAnalytics(app);

createApp(App).use(router).use(store).mount('#app')
