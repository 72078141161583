<template>
  <div class="home-container">
    <h1>{{ msg }}</h1>
    <p>Home page containing todo and useful links.</p>
    <h3>Todo</h3>
    <br />
    <ul class="todo-list">
      <li>Work on shamudeen.com</li>
      <li>Liam Birthday!</li>
      <li>Housewarming!</li>
    </ul>
    <h3>Photos</h3>
    <ul>
      <li class="li-inline">
        <a
          href="https://photos.google.com/album/AF1QipPW0W8_C5Ij9fPqupbHZrQVzuwD71ie5cYNARv9"
          target="_blank"
          rel="noopener"
        >Family</a>
      </li>
      <li class="li-inline">
        <a href="https://photos.google.com/search/Leo" target="_blank" rel="noopener">Leo</a>
      </li>
      <li class="li-inline">
        <a href="https://photos.google.com/search/Liam" target="_blank" rel="noopener">Liam</a>
      </li>
    </ul>
    <h3>Youtube</h3>
    <ul>
      <li class="li-inline">
        <a
          href="https://www.youtube.com/results?search_query=lcs+highlights+"
          target="_blank"
        >LCS Highlights</a>
      </li>
      <li class="li-inline">
        <a href="https://www.youtube.com/results?search_query=nba" target="_blank">NBA</a>
      </li>
      <li class="li-inline">
        <a href="https://www.youtube.com/results?search_query=nhl" target="_blank">NHL</a>
      </li>
      <li class="li-inline">
        <a
          href="https://www.youtube.com/results?search_query=2+hour+epic+music"
          target="_blank"
        >Music</a>
      </li>
      <li class="li-inline">
        <a href="https://www.youtube.com/results?search_query=cocomelon" target="_blank">Kids Music</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
.li-inline {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* :global(body) {
  background-image: url("../assets/green-background1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
.home-container {
  background-color: #dcffefcc;
  padding: 20px;
  padding-bottom: 50px;
}

.todo-list {
  margin: auto;
  width: 60%;
}

/* Style the list items */
.todo-list li {
  text-align: left;
  cursor: pointer;
  position: relative;
  padding: 12px 8px 12px 40px;
  background: #17533c;
  color: white;
  transition: 0.2s;
}

/* Set all odd list items to a different color (zebra-stripes) */
.todo-list li:nth-child(odd) {
  background: #f9f9f9;
  color: #17533c;
}

/* Darker background-color on hover */
.todo-list li:hover {
  background: #ddd;
}
</style>
